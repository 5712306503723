import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import Image from "../components/image/austellungen_thumbnails"
import { withTrans } from "../i18n/withTrans"
import i18next from "../i18n/config"

import Searchbar from "../components/searchbar"

const Ausstellungen = ({t, location}) => {
    const data = useStaticQuery(graphql`
      query allAusstellungenQuery {
        allGoogleAusstellungenSheet {
          nodes {
            ausstellung
            ausstellungEn
            jahr
            jahrEn
            ort
            ortEn
            thumbnailurl
            ausstellungEn
            gruppe
          }
        }
      }
    `)


    const [state, setState] = useState({
        query: "",
    })


    const tr = (de,en)=>(i18next.language==="de"?de:en)

    const handleInputChange = (change) => {setState({query: change.target.value});}
    const isUnfiltered = (exi) => {return !state.query
        || (exi.ausstellung && exi.ausstellung.toLowerCase().includes(state.query.toLowerCase()))
        || (exi.ausstellungEn && exi.ausstellungEn.toLowerCase().includes(state.query.toLowerCase()))
        || (exi.ort && exi.ort.toLowerCase().includes(state.query.toLowerCase()))
        || (exi.jahr && exi.jahr.toLowerCase().includes(state.query.toLowerCase()))
    }

    const Category = (nodes)=>{
      return(
      <>
      <div style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
      }}>
      {nodes.length > 0 && nodes.sort((first, second)=>{return (
        // sort exhibition entries chronologically
        (parseInt(first.jahr.replace(/\/./,"").slice(-4))||0) > (parseInt(second.jahr.replace(/\/./,"").slice(-4))||0)
      )}).map(exi => (
          <Link to={`${exi.ausstellung.toLowerCase().replace(/\W/g, '')}`}
              style={{textDecoration: "none", display: isUnfiltered(exi)?"block":"none"  }}>
              <div style={{margin: "auto", padding:"0.5em", maxWidth:"500px"}}>
                  <div style={{
                      overflow: "hidden",
                      height: "0", paddingTop:"100%", //hack to force 1:1 aspect ratio
                      // border:"#696969", borderWidth:"2px", borderStyle:"solid",
                  }}>
                      <Image
                        src={"ausstellungen/"+exi.thumbnailurl+".jpg"}
                        style={{width:"100%", transform:"translateY(-100%)"}}/>
                  </div>
                  <p style={{fontFamily:"Gotham", fontSize:"11pt", color: "black", textDecoration:"none", lineHeight: "14pt", marginTop:"10px"}}>
                      {tr(exi.ausstellung, exi.ausstellungEn)} <br/> {tr(exi.jahr, exi.jahrEn)}  <br/> {tr(exi.ort, exi.ortEn)}
                  </p>
              </div>
          </Link>
      ))}
      {// make all elemnts equal size by buffering underfilled grids with unrendered items
        [...Array(8)].map(() => (<div/>))}
      </div>
      </>
    )}

    const Heading = (text) => {
      return (
        <>
          <div style={{margin:"0 auto", display:"block", width:"100%"}}>
            <p style={{fontFamily:"BlueMono", fontSize:"11pt", color: "black",  marginTop:"100px", marginBottom:"50px",padding:"10px"}}>
              {text}
            </p>
          </div>
        </>
      )
    }

    return (
        <Layout>
            <Seo title="Ausstellungen"/>
            <main id="maincontent">
            <div style={{marginLeft: "15vw", marginRight:"15vw",marginTop:"20px"}}>

                <Searchbar handler={handleInputChange}
                  initVal={(location&&location.state&&location.state.filterQuery!="")?location.state.filterQuery:""}/>
                <div style={{marginTop:"30vh"}}/>
                  {Heading(t("ausstellung.historic"))}
                  {Category(data.allGoogleAusstellungenSheet.nodes.filter(elem => elem.gruppe === 1))}
                  {Heading(t("ausstellung.current"))}
                  {Category(data.allGoogleAusstellungenSheet.nodes.filter(elem => elem.gruppe === 2))}
                <div style={{marginBottom: "60vh"}}/>

              </div>
            </main>
        </Layout>
    )
}

export default withTrans(Ausstellungen)
