import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"

const Image = ({ src, type, ...rest }) => {
  const data = useStaticQuery(graphql`
    {
      images: allFile(
        filter: {internal: {mediaType: {regex: "/image/"}}, relativeDirectory: {eq: "ausstellungen"}}
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(width: 500, placeholder: TRACED_SVG, formats: [AVIF, WEBP, AUTO], aspectRatio: 1)
            }
          }
        }
      }
    }
  `);

  // formats: [WEBP, AUTO, AVIF]

  const match = useMemo(
    () => data.images.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  );

  if (!match) return null;

  const { node: { childImageSharp, publicURL, extension } = {} } = match;

  if (extension === 'svg' || !childImageSharp) {
    return <img alt={publicURL} src={publicURL} {...rest} />;
  }

  return <GatsbyImage image={childImageSharp.gatsbyImageData} {...rest} alt={childImageSharp.publicURL}/>;
};


export default Image;
